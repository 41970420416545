<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmapplication.formtitle')">
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('pmapplication.appname')"
								v-model="model.appname"
								required
								toplabel
								maxlength="30"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('pmapplication.urlbase')"
								v-model="model.urlbase"
								required
								toplabel
								maxlength="200"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('pmapplication.urlproduct')"
								v-model="model.urlproduct"
								required
								toplabel
								maxlength="200"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-select
								v-model="iconSelected"
								:items="items"
								:label="this.$t('pmapplication.iconname')"
								clearable
								:disabled="formDisabled"
								rounded
								required
								:rules="rules.select"
								class="py-2 px-1"
								variant="outlined"
							>
								<template v-slot:selection="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
								<template v-slot:item="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
							</v-select>
						</v-flex>
						<v-flex xs12 md6>
							<v-select
								v-model="faviconSelected"
								:items="items"
								:label="this.$t('pmapplication.faviconname')"
								clearable
								:disabled="formDisabled"
								rounded
								required
								:rules="rules.select"
								class="py-2 px-1"
								variant="outlined"
							>
								<template v-slot:selection="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
								<template v-slot:item="{ item }">
									<div :style="itemStyle(item)"></div>
									{{ item.name }}
								</template>
							</v-select>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmapplication.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import constants from '@/utils/constants';
import { isSuperAdmin, getIconsType } from '@/api/common';

export default {
	name: 'pmapplicationform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmapplication',
			model: 'pmapplication',
			isSuperAdmin: false,
			items: [],
			iconSelected: null,
			faviconSelected: null,
			rules: {
				select: [(v) => !!v || this.$t('common.required')]
			}
		};
	},
	watch: {
		iconSelected: {
			handler() {
				if (this.iconSelected) {
					this.model.pmiconid = this.iconSelected.pmiconsid;
				}
			},
			deep: true
		},
		faviconSelected: {
			handler() {
				if (this.faviconSelected) {
					this.model.pmfaviconid = this.faviconSelected.pmiconsid;
				}
			},
			deep: true
		}
	},
	computed: {},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.items = getIconsType(constants.APP_ICONS_TYPE_ACRONYM).concat(
			getIconsType(constants.DEFAULT_APP_ICONS_TYPE, this.$store.getters.getIcons)
		);
	},
	beforeUpdate() {
		console.log('info: beforeupdate application form', getIconsType(constants.DEFAULT_APP_ICONS_TYPE, this.$store.getters.getIcons));
		if (this.model.pmiconid) {
			this.iconSelected = this.items.find((element) => element.pmiconsid == this.model.pmiconid);
		} else {
			this.iconSelected = this.items.find(
				(element) => element.pmiconsid == getIconsType(constants.DEFAULT_APP_ICONS_TYPE, this.$store.getters.getIcons)[0].pmiconsid
			);
		}
		if (this.model.pmfaviconid) {
			this.faviconSelected = this.items.find((element) => element.pmiconsid == this.model.pmfaviconid);
		} else {
			this.faviconSelected = this.items.find(
				(element) => element.pmiconsid == getIconsType(constants.DEFAULT_APP_ICONS_TYPE, this.$store.getters.getIcons)[1].pmiconsid
			);
		}
	},
	methods: {
		itemStyle(item) {
			var appProperties = this.$store.getters.getApplication;
			return 'content: url(' + appProperties.urlbase + item.route + '); margin-right: 1rem; transform: scale(0.7); width: 38px';
		}
	}
};
</script>
